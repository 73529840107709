import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

function ApiCallForm() {
  const [userInput, setUserInput] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_LLM_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: userInput,
        }),
      });

      if (!response.ok) {
        throw new Error(`API call failed: ${response.status}`);
      }

      const data = await response.json();
      setApiResponse(data.response);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setUserInput(""); // Clear input after submission
    }
  };

  return (
    <div className="App">
      <h1>Make a POST Request</h1>
      <FormControl>
        <FormLabel>Prompt</FormLabel>
        <Input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
        />
        <Button onClick={handleSubmit}>Submit</Button>
      </FormControl>

      {apiResponse && (
        <Card>
          <CardBody>
            <Text>{apiResponse}</Text>
          </CardBody>
        </Card>
      )}

      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  );
}

export default ApiCallForm;
