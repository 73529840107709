import React from "react";
import ApiCallForm from "./components/ApiCallForm";

function App() {
  return (
    <div className="App">
      <ApiCallForm />
    </div>
  );
}

export default App;
